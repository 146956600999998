<template>
  <div>
    <div style="margin-top : 15px;">
      <div style="width : 96%;margin-left : 4%" class="groups">
          <div v-for="(item) in user_id" :key="item.user_id" class="userIdFor" @click="radio = item.user_id"> 
              <div class="right">
                <div>
                  <!-- <img :src="item.gender == 2 ? nvImg : item.gender == 1 ?  nanImg : nanImg" /> -->
                  <img :src="nanImg" />
                  <!-- <img src="@/assets/images/staff/information2.png" /> -->
                </div>
                <div @click="awaken(item.user_id)" class="userIdForr">
                  <div>
                    <wxComponent type="userName" :openid="item.user_id" />
                  </div>
                  <div>
                    <wxComponent type="departmentName" :openid="item.position" />
                  </div>
                </div>
              </div>
              <!-- <div class="rightImg" @click="awaken(item.user_id)">
                <img src="@/assets/images/staff/tel.png" alt="">
              </div> -->
          </div>
          <div class="rlenght">
            共{{user_id.length}}人
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import wxComponent from "@/components/wx";
export default {
  name: "pickhtml",
  components: { wxComponent },

  data() {
    return {
      radio: "",
      user_id: [],
      nanImg : require("@/assets/images/staff/nan1.png"),
      type: 1,
      // nanImg : require("@/assets/images/staff/nan.png"),
      // nvImg : require("@/assets/images/staff/nv.png"),
      // undefinedImg : require("@/assets/images/staff/undefined.png")
    };
  },

  created() {
    if (this.$route.query && this.$route.query.user_id) {
      this.user_id = JSON.parse(this.$route.query.user_id);
      this.type = this.$route.query.type;
    }
  },

  methods: {
    cancel() {
      if (this.type == 1) {
        wx.closeWindow();
      } else {
        this.$router.go(-1)
      }
    },
    awaken(user_id) {
      // 唤醒会话
      // if (this.radio.length == 0) {
      //   this.$message.error("请先选择人员");
      //   return;
      // }
      wx.openEnterpriseChat({
        userIds: user_id, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        groupName: "", // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: (res) => {
          // 回调
          if (this.type == 1) {
            wx.closeWindow();
            return;
          }
          this.$router.go(-1)
        },
        fail: (res) => {
          if (res.errMsg.indexOf("function not exist") > -1) {
            this.$message.error("版本过低请升级");
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.rlenght{
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    text-align: center;
    margin-top: 5px;
}
.right {
  align-items: center;
  margin-left: 2px;
  display: flex;
  width: 100%;
  > div:nth-child(1) {
    background: #d6dbdf;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    img {
      height: 40px;
      width: 40px;
    }
  }
  > div:nth-child(2) {
    width: 90%;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div:nth-child(1) {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      color: #303133;
    }
    > div:nth-child(2) {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #909499;
    }
  }
}
.rightImg{
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 15px;
  width: 38px;
  position: absolute;
  right: 2px;
  img{
    width: 40px;
    height: 40px;
  }
}
.userIdFor {
  width: 100%;
  height: 56px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .userIdForr{
    
  box-shadow: 0px 1px 0px #F5F5F5;
  }
}
.groups {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.1);
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .bottomLeft {
    width: 156px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
  .bottomRigth {
    width: 156px;
    height: 36px;
    background: #0078ef;
    border-radius: 4px;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }
}
// .group{
//   margin-bottom: 10px;
// }
</style>